import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { StyledText } from '~/shared/components/Text';
import { AlignContentProps } from './AlignContent';
import { StyledRichText } from '~/shared/components/RawHtml/styled';

export const StyledAlignContent = styled.div<AlignContentProps>(
    ({ textColor, maxWidth }) => ({
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        color: textColor,
        gap: 15,
        [`${StyledText}, ${StyledRichText}, p`]: {
            maxWidth: maxWidth || 500,
        },
    }),
    switchProp('alignment', {
        left: {
            alignItems: 'flex-start',
        },
        right: {
            alignItems: 'flex-end',
            textAlign: 'right',
        },
        center: {
            alignItems: 'center',
            textAlign: 'center',
        },
    }),
    switchProp('position', {
        top: {
            justifyContent: 'flex-start',
        },
        center: {
            justifyContent: 'center',
        },
        bottom: {
            justifyContent: 'flex-end',
        },
    }),
);
